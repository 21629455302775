<template>
  <div>
    <a-modal
      v-model="visibleModalAddStudent"
      class="modal-add-student"
      :title="`Add Student(s) to ${manageStudentData.tingkat}-${manageStudentData.simbol} Class`"
      @cancel="toggleModalAddStudent"
      :destroyOnClose="true"
      width="70%"
    >
      <add-student
        :dataStudent="manageStudentData"
        @close-modal="toggleModalAddStudent"
      />
      <template slot="footer">
        <a-button
          class="d-none m-0"
          size="large"
          key="back"
          @click="toggleModalAddStudent"
        >
          Tutup
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex">
      <div class="mr-3">
        <a-input
          v-model="search"
          @keyup="searchStudent"
          size="large"
          placeholder="Search Student..."
        />
      </div>
      <div class="ml-auto">
        <a-button
          @click.prevent="removeStudentSelected"
          :disabled="!selectedRowStudent.length"
          class="ml-3"
          type="danger"
          size="large"
          :loading="loadingDeleteStudent"
        >
          <a-icon v-if="!loadingDeleteStudent" type="delete" />
          {{
            loadingDeleteStudent
              ? "Removing..."
              : `Remove ${
                  selectedRowStudent.length ? selectedRowStudent.length : ""
                } Selected Student(s)`
          }}
        </a-button>
        <a-button
          @click.prevent="toggleModalAddStudent"
          class="ml-3"
          type="primary"
          size="large"
        >
          <a-icon type="plus" />Add Student
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="studentColumns"
        :data-source="studentDataTable"
        :pagination="paginationStudent"
        :loading="loadingTableStudent"
        :row-selection="rowSelection"
        @change="handleTableChange"
        bordered
      >
        <div slot="footer">Total Student(s): {{ paginationStudent.total }}</div>
        <div slot="nis" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="nama" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="jenis_kelamin" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
        <div slot="agama" slot-scope="value">
          <span v-if="value"> {{ value }}</span>
          <span v-else>-</span>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const addStudent = () => import('@/components/app/Admin/SchoolYear/StudentClass/FormManageStudent/AddStudent')
const studentColumns = [
  {
    title: 'NIS',
    dataIndex: 'nis',
    key: 'nis',
    // width: 250,
    sorter: true,
    scopedSlots: { customRender: 'nis' },
  },
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    sorter: true,
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Gender',
    dataIndex: 'jenis_kelamin',
    key: 'jenis_kelamin',
    // width: 200,
    sorter: true,
    scopedSlots: { customRender: 'jenis_kelamin' },
  },
  {
    title: 'Religion',
    dataIndex: 'agama',
    key: 'agama',
    // width: 200,
    scopedSlots: { customRender: 'agama' },
  },
]
export default {
  name: 'ManageStudent',
  props: {
    manageStudentData: {
      type: Object,
      required: true,
    },
  },
  components: {
    addStudent,
  },
  data() {
    return {
      studentColumns,
      studentDataTable: [],
      selectedRowStudent: [],
      paginationStudent: {},
      visibleModalAddStudent: false,
      loadingTableStudent: false,
      loadingDeleteStudent: false,
      search: '',
    }
  },
  methods: {
    removeStudentSelected() {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div class="d-flex flex-column"><p>Are you sure want to remove {this.selectedRowStudent.length} student(s)?</p><p class="text-danger mt-4">*This change will be affected to system</p></div>
        ),
        onOk: () => {
          this.loadingDeleteStudent = true
          this.$store.dispatch('studentClass/REMOVE_STUDENT_CLASS_SELECTED', { dataStudents: this.selectedRowStudent })
            .then(isSuccess => {
              this.loadingDeleteStudent = false
              this.fetchDataStudent()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description: `${this.selectedRowStudent.length} student(s) has been removed`,
                })
                this.selectedRowStudent = []
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: `${this.selectedRowStudent.length} student(s) has not been removed`,
                })
                this.selectedRowStudent = []
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove Now',
        cancelText: 'Cancel',
      })
    },
    toggleModalAddStudent() {
      this.visibleModalAddStudent = !this.visibleModalAddStudent
      if (!this.visibleModalAddStudent) {
        this.fetchDataStudent()
      }
    },
    searchStudent() {
      this.fetchDataStudent({
        search: this.search,
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.paginationStudent }
      let order = 'ASC'
      let sortBy = ''
      pager.current = pagination.current
      this.paginationStudent = pager
      if (sorter) {
        sortBy = sorter.field
        // console.log(sorter)
        if (sorter.order === 'ascend') {
          order = 'ASC'
        } else if (sorter.order === 'descend') {
          order = 'DESC'
        }
      }
      this.fetchDataStudent({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    fetchDataStudent(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTableStudent = true
      const id = this.manageStudentData.id
      this.$store.dispatch('studentClass/FETCH_LIST_STUDENT_OF_CLASS', { id, page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTableStudent = false
          const pagination = { ...this.paginationStudent }
          pagination.total = res.total
          this.paginationStudent = pagination
          this.studentDataTable = res.students.map(el => {
            return {
              ...el,
              key: el.id,
              nis: el.murid.nis,
              nama: el.murid.nama,
              jenis_kelamin: el.murid.jenis_kelamin,
              agama: el.murid.agama,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataStudent()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          const newData = selectedRows.filter(row => selectedRowKeys.find(key => key === row.id))
          const oldData = []
          for (let i = 0; i < this.selectedRowStudent.length; i++) {
            const student = this.selectedRowStudent[i]
            const findStudent = newData.find(dat => dat.id === student.id)
            if (!findStudent) {
              const isOnPage = this.studentDataTable.find(dat => dat.id === student.id)
              if (!isOnPage) oldData.push(student)
            }
          }
          this.selectedRowStudent = oldData.concat(newData)
        },
        selectedRowKeys: this.rowSessionSelectedKey,
      }
    },
    rowSessionSelectedKey() {
      return this.selectedRowStudent.map(row => row.key)
    },
  },
}
</script>

<style>
</style>
